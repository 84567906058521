import { TimeIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import ProfileDataFormModal from "../../../components/ProfileDataFormModal";
import {
  fintocLogo,
  mpLogo,
  pagoHubLogo,
  webpayLogo,
} from "../../../resources";

import { PaymentSummaryProps } from "./interfaces";
import { usePaymentSummaryController } from "./payment-summary.controller";

export const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  useController = usePaymentSummaryController,
}) => {
  const controller = useController();

  const [imageLoaded, setImageLoaded] = useState(true);

  const [termsAccepted, setTermsAccepted] = useState(false);
  
  useEffect(() => {
    setImageLoaded(false);
  }, [controller.imageURL]);
  return (
    <Box
      borderColor={"secondary.main"}
      borderWidth={"3px"}
      borderRadius={"xl"}
      p={5}
      margin={"auto"}
      width={"max-content"}
    // maxW={"100vw"}
    >
      <Box
        display={"flex"}
        flexDir={["column-reverse", "column-reverse", "row"]}
        justifyContent="space-between"
        alignItems={["left", "left", "center"]}
      >
        <Text
          marginBottom={[2, 2, 0]}
          maxW={"80vw"}
          fontWeight={600}
          fontSize={24}
          color={"backgroundContrast"}
        >
          Resumen de tu compra:
        </Text>
        <Box
          my={5}
          p={2}
          borderRadius={"xl"}
          borderWidth={1}
          borderColor={
            controller.sessionExpired ? "red.main" : "backgroundContrast"
          }
          fontWeight={600}
          fontSize={24}
          display={"flex"}
          alignItems={"center"}
          width={"max-content"}
          justifyContent={"space-between"}
          minW={"125px"}
        >
          <TimeIcon
            mr={2}
            color={
              controller.sessionExpired ? "red.main" : "backgroundContrast"
            }
          />
          <Text
            color={
              controller.sessionExpired ? "red.main" : "backgroundContrast"
            }
          >
            {controller.timer}
          </Text>
        </Box>
      </Box>
      <Box
        bgColor={"secondary.main"}
        width={["80vw", "xs", "max-content"]}
        height={"max-content"}
        borderRadius={"xl"}
        overflow={"hidden"}
        display={"flex"}
        flexDirection={["column", "column", "row"]}
      >
        <Box width={["100%", "100%", "md"]}>
          <Skeleton
            startColor="secondary.800"
            endColor="secondary.700"
            width={"100%"}
            style={{ aspectRatio: 2.2 }}
            isLoaded={!controller.eventLoading && imageLoaded}
          >
            <Image
              src={controller.imageURL}
              onLoad={() => setImageLoaded(true)}
              onError={() => setImageLoaded(true)}
              fallback={<Box height={"100%"} width={"100%"}></Box>}
              overflow={"hidden"}
              width={"100%"}
              borderLeftRadius={[0, 0, "xl"]}
              borderTopRadius={["xl", "xl", 0]}
              style={{ aspectRatio: 2.2 }}
            />
          </Skeleton>
        </Box>
        <Box
          px={6}
          py={4}
          display={"flex"}
          flexDir={"column"}
          justifyContent="space-between"
        >
          <Box>
            <Text color={"secondary.contrast"} fontWeight={600}>
              {controller.title}
            </Text>
            <Text color={"secondary.contrast"}>{controller.date}</Text>
            <Text color={"secondary.contrast"}>{controller.location}</Text>
          </Box>
          <Divider my={2} />
          <HStack spacing={3} dir="row" wrap={"wrap"} alignItems={"center"}>
            <Text color={"secondary.contrast"}>Precio Final:</Text>
            {controller.isDiscounted && controller.originalPrice && (
              <Text
                fontSize={20}
                fontWeight={600}
                color={"muted.400"}
                textDecoration={"line-through"}
              >
                {controller.originalPrice}
              </Text>
            )}
            <Text
              fontWeight={600}
              fontSize={20}
              color={
                controller.isDiscounted ? "primary.main" : "secondary.contrast"
              }
            >
              {controller.priceTotal}
            </Text>
            {controller.discountAmount && (
              <Badge
                colorScheme="primary"
                fontSize={18}
                color={"black"}
                bgColor={"primary.main"}
                textTransform={"none"}
                borderRadius={4}
                px={2}
                py={1}
              >
                {controller.discountAmount}
              </Badge>
            )}
          </HStack>
        </Box>
      </Box>
      <Text
        maxW={"80vw"}
        my={5}
        fontWeight={600}
        fontSize={24}
        color={"backgroundContrast"}
      >
        Seleccioná la forma de pago:
      </Text>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={["center", "space-between"]}
        flexDirection={["column", "row"]}
        borderRadius={"xl"}
        w={["80vw", "full"]}
        margin={"auto"}
        cursor="pointer"
      >
        <RadioGroup
          // defaultValue={controller.selectedMethod}
          value={controller.selectedMethod}
          onChange={controller.handleMethodChange}
          display={"flex"}
          justifyContent={["center", "space-between"]}
          flexDirection={["column"]}
          gap={8}
          w={"full"}
          px={40}
        >
          {controller.paymentMethods?.includes("PH") && (
            <Box
              backgroundColor={"white"}
              borderRadius={20}
              border={"2px solid"}
              display={"flex"}
              w={"full"}
              justifyContent={"center"}
            >
              <Radio
                borderColor={"black"}
                px={"16px"}
                py={"8px"}
                value="pagoHub"
                size={"lg"}
                w={"full"}
                ml={'75px'}
              >
                <HStack spacing={4}
                  alignItems={"center"}
                >
                  <Image src={webpayLogo} width={70} />
                  <Image src={fintocLogo} width={70} />
                </HStack>
                {/* <Image src={pagoHubLogo} width={100} /> */}
              </Radio>
            </Box>
          )}
          {controller.paymentMethods?.includes("MP") && (
            <Box
              w={"full"}
              backgroundColor={"white"}
              borderRadius={20}
              border={"2px solid"}
            >
              <Radio
                borderColor={"black"}
                _selected={{ bgColor: "transparent" }}
                px={"16px"}
                py={"8px"}
                value="mercadoPago"
                size={"lg"}
                w={"full"}
                ml={'75px'}
              >
                <Image src={mpLogo} width={100} />
              </Radio>
            </Box>
          )}
          {controller.paymentMethods?.includes("FL") && (
            <Box
              w={"full"}
              backgroundColor={"white"}
              borderRadius={20}
              border={"2px solid"}
              display={"flex"}
            >
              <Radio
                borderColor={"black"}
                _selected={{ bgColor: "transparent" }}
                px={"16px"}
                py={"8px"}
                value="flow"
                size={"lg"}
                w={"full"}
                ml={'75px'}
              >
                <Image src={'/assets/payments/cardLogos/FlowLogo.svg'} width={100} />
              </Radio>
            </Box>
          )}
        </RadioGroup>
      </Box>
      <Box mt={10} w={"full"} textAlign={"center"}>
        {!controller.isDiscounted ? (
          <Stack
            direction={["column", "column", "row"]}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Stack display={"flex"} flexGrow={1} maxW={[null, null, "50%"]}>
              <Input
                display={"flex"}
                value={controller.discount}
                placeholder="Código de descuento"
                color={"backgroundContrast"}
                borderColor={"backgroundContrast"}
                _placeholder={{ color: "backgroundContrast" }}
                onChange={(e) =>
                  controller.setDiscount(e.target.value.toUpperCase())
                }
                isInvalid={Boolean(controller.discountError)}
              />
              {controller.discountError && (
                <Text fontSize={14} color={"red.main"}>
                  Código inválido
                </Text>
              )}
            </Stack>
            <Button
              variant={"outline"}
              disabled={controller.discount.length === 0}
              borderRadius={"full"}
              colorScheme={"primary"}
              textAlign={"center"}
              px={10}
              display={"flex"}
              isLoading={controller.discountLoading}
              onClick={controller.onDiscountSubmit}
            >
              Aplicar
            </Button>
          </Stack>
        ) : (
          <Text fontWeight={500} color={"green.500"}>
            ¡Descuento aplicado!
          </Text>
        )}
      </Box>
      <Box mt={4} display={"flex"} justifyContent={"center"} maxW={"80vw"}>
        <Checkbox
          isChecked={termsAccepted}
          onChange={(e) => setTermsAccepted(e.target.checked)}
          colorScheme={"muted"}
          color={"backgroundContrast"}
        >
          Acepto los{" "}
          <Link
            isExternal
            textDecoration={"underline"}
            as={RouterLink}
            to="/terms"
          >
            términos y condiciones
          </Link>{" "}
          para comprar en Chiletiket
        </Checkbox>
      </Box>

      <Box w={"100%"} display={"flex"} justifyContent={"center"}>
        <Button
          disabled={!termsAccepted || controller.sessionExpired}
          borderRadius={"full"}
          width="full"
          colorScheme={"primary"}
          textAlign={"center"}
          mt={5}
          px={10}
          isLoading={controller.isPaymentLoading}
          onClick={controller.onBuy}
        >
          PAGAR
        </Button>
      </Box>
      <ProfileDataFormModal
        isOpen={controller.isFormOpen}
        onClose={controller.onFormClose}
        onSubmitDone={controller.onPayment}
      />
    </Box>
  );
};
